import React, { useEffect } from 'react';
import MediaQuery from 'react-responsive';

export default function Tableau() {
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
  return (
    <>
    <MediaQuery minWidth={1160}>
    
<div className='container' style={{margin:'0px auto'}}>
<iframe frameborder="0" marginheight="0" marginwidth="0" title="Data Visualization" allowtransparency="true" allowfullscreen="true" class="tableauViz" style={{display: 'block', width: '1000px', height: '827px', margin: '0px', padding: '0px', border: 'none'}} src="https://public.tableau.com/views/Overview_PFL/Dashboard1?:embed=y&amp;:display_count=y?:embed=y&amp;:showVizHome=no&amp;:host_url=https%3A%2F%2Fpublic.tableau.com%2F&amp;:embed_code_version=3&amp;:toolbar=yes&amp;:animate_transition=yes&amp;:display_static_image=no&amp;:display_spinner=no&amp;:display_overlay=yes&amp;:display_count=yes&amp;:loadOrderID=0"></iframe>
</div> 
</MediaQuery>


    <MediaQuery maxWidth={1159}>
    
<div style={{margin:'0px auto',width:'-webkit-fill-available'}}>
<iframe frameborder="0" marginheight="0" marginwidth="0" title="Data Visualization" allowtransparency="true" allowfullscreen="true" class="tableauViz" style={{display: 'block', width: '-webkit-fill-available', height: '850px', margin: '0px', padding: '0px', border: 'none'}} src="https://public.tableau.com/views/Overview_PFL/Dashboard1?:embed=y&amp;:display_count=y?:embed=y&amp;:showVizHome=no&amp;:host_url=https%3A%2F%2Fpublic.tableau.com%2F&amp;:embed_code_version=3&amp;:toolbar=yes&amp;:animate_transition=yes&amp;:display_static_image=no&amp;:display_spinner=no&amp;:display_overlay=yes&amp;:display_count=yes&amp;:loadOrderID=0"></iframe>
</div> 
</MediaQuery>
</>


  )
}
