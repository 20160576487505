import React, { useEffect } from 'react';

export default function FemaTableau() {

    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
  return (
<div style={{margin:'0px auto'}}>
<iframe frameborder="0" marginheight="0" marginwidth="0" title="Data Visualization" allowtransparency="true" allowfullscreen="true" class="tableauViz" style={{display: 'block', width: '100%', height: '1152px', margin: '0px', padding: '0px', border: 'none'}} src="https://public.tableau.com/views/FEMAIncidents/D_Total_Incidents?:embed=y&amp;:showVizHome=no&amp;:host_url=https%3A%2F%2Fpublic.tableau.com%2F&amp;:embed_code_version=3&amp;:tabs=no&amp;:toolbar=yes&amp;:animate_transition=yes&amp;:display_static_image=no&amp;:display_spinner=no&amp;:display_overlay=yes&amp;:display_count=yes&amp;:loadOrderID=0"></iframe>
</div> 


  )
}
