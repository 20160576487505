import React, { useContext, useState } from 'react'
import '../style.scss'
import {
   useNavigate,
} from "react-router-dom";
// import axios from 'axios';
import { AuthContext } from '../context/authContext';
import navlogo from "../Assets/Img/eigennet_RESEARCHLAB_logo.svg"
import HelmetForTitle from '../components/HelmetForTitle';


export default function Login() {
  const [inputs, setInputs]=useState({
    username:'',
    password:''
  })

  const [err,setErr]= useState(null)

  const {login}= useContext(AuthContext);
console.log(login)

  const handleChange = e =>{
setInputs(prev=>({...prev,[e.target.name]:e.target.value}))
  }
const navigate = useNavigate()
  // console.log(inputs)
const handleSubmit = async (e) =>{
  e.preventDefault()   
  try{
    await login(inputs)
  // const res = await axios.post("/auth/register",inputs)
  navigate('/')
}catch(err){
  setErr(err.response.data)
}
}

  return (
    <>
    <HelmetForTitle/>
    <div className='auth'>
      <div className='container'>
        <div className="row">
          <div className="col-sm-12 col-md-6 col-lg-6" style={{display:'flex',alignItems:'center',justifyContent:'center'}}>
      <img src={navlogo} style={{width:'350px'}}  alt="Eigennet"></img><br></br>
    </div>
    <div className="col-sm-12 col-md-6 col-lg-6" >
    <form>
<h1 className='text-center'>Login</h1>
    <input type="text" placeholder= 'Username' name='username' onChange={handleChange}/>
    <input type="password" placeholder='Password' name='password' onChange={handleChange}/>
    <button className='btn btn-rounded' onClick={handleSubmit}>Login</button>
    {err && <p>{err}</p>}
    {/* <span>Don't have an account ?<br/><Link to='/Register'>Register</Link></span> */}
    </form>  
</div>
    </div> 
     </div>
     </div>
     </>
  )
}
