import axios from 'axios';
import moment from 'moment';
import Error404 from './Error404'
import React, { useContext, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { AuthContext } from '../context/authContext';
import MediaQuery from 'react-responsive';

import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import { S3Client, PutObjectCommand } from '@aws-sdk/client-s3';
import { v4 as uuidv4 } from 'uuid';
import HelmetForTitle from '../components/HelmetForTitle';

export default function Write() {
  const { currentUser } = useContext(AuthContext);
  const state = useLocation().state;

  const [value, setValue] = useState(state?.desc || '');
  const [title, setTitle] = useState(state?.title || '');
  // const [file, setFile] = useState(state?.img || '');
  const [file, setFile] = useState(null);
  const [cat, setCat] = useState(state?.cat || '');
  const [hashtag, setHashTag] = useState(state?.hashtag || '');

  // const [tag, setTag] = useState(state?.tag || '');

  const navigate = useNavigate();

  // const upload = async () => {
  //   try {
  //     const formData = new FormData();
  //     formData.append('file', file);
  //     const res = await axios.post('/api/upload', formData);
  //     console.log("File data"+res);
  //     return res.data;
  //   } catch (err) {
  //     console.log(err);
  //   }
  // };

  const s3 = new S3Client({
    region: 'us-east-1',
    credentials: {
      accessKeyId: 'AKIASANVGLDBFL3QJUUD',
      secretAccessKey: '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',
    },
  });

  const upload = async () => {
    try {
      const formData = new FormData();
      const fileName = `media/imgs/${uuidv4()}-${file.name.replace(/\s/g, '')}`; // Generate a unique filename
      formData.append('file', file, fileName);
      const command = new PutObjectCommand({
        Bucket: 'blog-eigennet-uploads',
        Key: fileName,
        Body: file,
      });
      await s3.send(command);
      return fileName;
    } catch (err) {
      console.error('Error uploading file:', err);
      return '';
    }
  };
  

  const handleSubmit = async (e) => {
    e.preventDefault();
    const uploadedFileName = await upload();
  
    try {
      state
        ? await axios.put(`/api/posts/${state.id}`, {
            title,
            desc: value,
            cat,
            img: uploadedFileName ? `https://blog-eigennet-uploads.s3.amazonaws.com/${uploadedFileName}` : '',
            hashtag,
          })
        : await axios.post(`/api/posts/`, {
            title,
            desc: value,
            cat,
            img: uploadedFileName ? `https://blog-eigennet-uploads.s3.amazonaws.com/${uploadedFileName}` : '',
            hashtag,
            date: moment(Date.now()).format('YYYY-MM-DD HH:mm:ss'),
          });
  
      navigate('/');
    } catch (err) {
      console.log(err);
    }
  };
  

// const submitButton = async (e) =>{
//   e.preventDefault();

//   try{
//     state
//      ? await axios.post(`/posts/hash`, {
//       tag
//      })
//      : await axios.post(`/posts/hash`,{
//       tag 
//      });
//      navigate('/write');

//   }catch(err){
//     console.log(err)

//   }
// }
const isFormValid = title && value && cat && hashtag && file;


  return (
    <>
    <HelmetForTitle/>
     {currentUser ? <MediaQuery minWidth={1160}>
        <div className="container">
          <h1 className="text-center mt-2">Write A Blog</h1>
          <div className="add mt-3">
            <div className="content">
              <input type="text" placeholder="Title" value={title} onChange={(e) => setTitle(e.target.value)} required/>
              <div className="editorContainer">
                <div className="editor">
                <CKEditor
                  editor={ClassicEditor}
                  data={value}
                  onChange={(event, editor) => setValue(editor.getData())}
                  required
                />
                </div>
              </div>
            {/* <label htmlFor="hashtag">Enter Custom HashTags</label> */}
    <input type="text" name='hashtag' id='hashtag' placeholder='Enter custom hashtag eg.: #Ai #Doc2data.ai' value={hashtag} onChange={(e) => setHashTag(e.target.value)} required/>
            <p className='mt-0' style={{fontSize:'13px'}}><span style={{color:'red'}}>Note*</span> : Please use Doc2data.ai as hashtag for every post.</p>
            </div>

 
            {/* <div className="container">
    <label htmlFor="tag">Enter Custom HashTags</label>
    <input type="text" name='hashtags' id='tag' onChange={(e) => setTag(e.target.value)}/>
    {currentUser && (
                  <div className="buttons mt-2">
                    <button className="btn-sm w-100" onClick={submitButton}>
                      Submit
                    </button>
                  </div>
                )}   
                 </div> */}


            <div className="menu">
              <div className="item">

                  <h1 className='font-weight-bold mt-3' style={{fontSize:'16px'}}>Defined HashTags(use for category)</h1>
                <div className="cat">
    <input type="radio" checked={cat === 'AI and IT Industry Trends'} name='cat' value='AI and IT Industry Trends' id='AI and IT Industry Trends' onChange={e=>setCat(e.target.value)}/>
    <label htmlFor="AI and IT Industry Trends">AI and IT Industry Trends</label>
    </div>
    <div className="cat">
    <input type="radio" checked ={cat === 'Use Cases and Case Studies'} name='cat' value='Use Cases and Case Studies' id='Use Cases and Case Studies' onChange={e=>setCat(e.target.value)}/>
    <label htmlFor="Use Cases and Case Studies">Use Cases and Case Studies</label>
    </div>
    <div className="cat">
    <input type="radio" checked ={cat === 'Company News'} name='cat' value='Company News' id='Company News' onChange={e=>setCat(e.target.value)}/>
    <label htmlFor="Company News">Company News</label>
    </div>
    <div className="cat">
    <input type="radio" checked ={cat === 'Opinion Pieces'} name='cat' value='Opinion Pieces' id='Opinion Pieces' onChange={e=>setCat(e.target.value)}/>
    <label htmlFor="Opinion Pieces">Opinion Pieces</label>
    </div>
    <div className="cat">
    <input type="radio" checked ={cat === 'Guest Blogs'} name='cat' value='Guest Blogs' id='Guest Blogs' onChange={e=>setCat(e.target.value)}/>
    <label htmlFor="Guest Blogs">Guest Blogs</label>
    </div>
                {/* Other category options */}
              </div>
              <div className="item">
                <h1 className='font-weight-bold mt-3' style={{fontSize:'16px'}} >Upload Cover Image</h1>
                <input type="file"  onChange={(e) => setFile(e.target.files[0])} required/>
                {/* Uploaded File:{file ? `/upload/${file}`: ''} */}
                {currentUser && (
                  <div className="buttons mt-2">
                    <button className="btn-sm w-100" onClick={handleSubmit} disabled={!isFormValid}>
                Publish
              </button>
            </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </MediaQuery>: <Error404/>}

      {currentUser ? <MediaQuery maxWidth={1160}>
        <div className="container">
          <h2 className="text-center mt-2">Write A Blog</h2>
          <div className="add1 mt-3">
            <div className="content1">
              <div className="item1">
                <h4>Upload cover Image</h4>
                <input type="file" onChange={(e) => setFile(e.target.files[0])} />
              </div>
              <label htmlFor="title" className="mb-0">
                <h4>Title</h4>
              </label>
              <input type="text" placeholder="Title" id="title" value={title} onChange={(e) => setTitle(e.target.value)} />
              <div className="item1">
                <h4 className="mb-4">Category</h4>
                <div className="cat">
    <input type="radio" checked={cat === 'AI and IT Industry Trends'} name='cat' value='AI and IT Industry Trends' id='AI and IT Industry Trends' onChange={e=>setCat(e.target.value)}/>
    <label htmlFor="AI and IT Industry Trends">AI and IT Industry Trends</label>
    </div>
    <div className="cat">
    <input type="radio" checked ={cat === 'Use Cases and Case Studies'} name='cat' value='Use Cases and Case Studies' id='Use Cases and Case Studies' onChange={e=>setCat(e.target.value)}/>
    <label htmlFor="Use Cases and Case Studies">Use Cases and Case Studies</label>
    </div>
    <div className="cat">
    <input type="radio" checked ={cat === 'Company News'} name='cat' value='Company News' id='Company News' onChange={e=>setCat(e.target.value)}/>
    <label htmlFor="Company News">Company News</label>
    </div>
    <div className="cat">
    <input type="radio" checked ={cat === 'Opinion Pieces'} name='cat' value='Opinion Pieces' id='Opinion Pieces' onChange={e=>setCat(e.target.value)}/>
    <label htmlFor="Opinion Pieces">Opinion Pieces</label>
    </div>
    <div className="cat">
    <input type="radio" checked ={cat === 'Guest Blogs'} name='cat' value='Guest Blogs' id='Guest Blogs' onChange={e=>setCat(e.target.value)}/>
    <label htmlFor="Guest Blogs">Guest Blogs</label>
    </div>
                {/* Other category options */}
              </div>
              <label>
                <h4>Description</h4>
              </label>
              <div className="editorContainer1">
                <CKEditor
                  editor={ClassicEditor}
                  data={value}
                  onChange={(event, editor) => setValue(editor.getData())}
                />
              </div>
              <input type="text" name='hashtag' id='hashtag' placeholder='Enter custom hashtag eg.: #Ai #Doc2data.ai' value={hashtag} onChange={(e) => setHashTag(e.target.value)}/>
            <p className='mt-0' style={{fontSize:'13px'}}><span style={{color:'red'}}>Note*</span> : Please use Doc2data.ai as hashtag for every post.</p>
            </div>
            <div className="menu1">
              {currentUser && (
                <div className="buttons1 mt-4">
                  <button className="btn-sm w-100" onClick={handleSubmit}>
                    Publish
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </MediaQuery> : <Error404/>}
    </>
  );
}
