import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import MediaQuery from 'react-responsive';
import LoadingBar from 'react-top-loading-bar';


const Menu = ({ cat, mainPostId }) => {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const [posts, setPosts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 2;
  // const [mainPostId, setMainPostId] = useState(null);
  // let mainPostId = mainPostId
  console.log('test',mainPostId)

  const handlePrevClick = () => {
    setCurrentPage((prevPage) => prevPage - 1);
  };

  const handleNextClick = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get(`/api/posts/?cat=${cat}`);
        setPosts(res.data);
        // if (res.data.length > 0) {
        //   setMainPostId(res.data.id);
        // }
      } catch (err) {
        console.log(err);
      }
    };
    fetchData();
  }, [cat]);

  const paginatedPosts = posts
  .filter((post) => post.id !== mainPostId) // Exclude the main post from the similar posts
    .slice((currentPage - 1) * pageSize, currentPage * pageSize);

  const totalPages = Math.ceil(posts.length / pageSize);

  const [isLoading, setIsLoading] = useState(false);
  const [loadingProgress, setLoadingProgress] = useState(0);

  console.log(isLoading)
  const handleLinkClick = () => {
    setIsLoading(true);
    setLoadingProgress(30); // Set initial progress value

    // Simulate loading delay (remove this in your actual code)
    setTimeout(() => {
      setLoadingProgress(100); // Set progress to complete
      setIsLoading(false);

      // Reset progress after a delay (optional)
      // setTimeout(() => {
      //   setLoadingProgress(0);
      // }, 500);
    }, 1000);
  };

  return (
    <>
      <LoadingBar progress={loadingProgress} height={5} color="#89C000" />

      <MediaQuery minWidth={1160}>
        <div className="menu">
          <h1 className="text-center font-weight-bold" style={{ fontSize: '22px' }}>Similar Blogs</h1>
          {<div>
          {paginatedPosts.map((post) => (
            <div className="post" key={post.id}>
              <img src={post?.img} alt="img" style={{objectFit:'cover',objectPosition:'top right'}}/>
              <h4 className="font-weight-bold" style={{fontSize:'18px'}}>{post.title }</h4>
              <Link className="link" to={`/post/${post.id}`}>
                <button className="mb-3" onClick={handleLinkClick}>Read More</button>
              </Link>
            </div>
          ))
 }
 </div>}
          
          {totalPages > 1 && (
            <div className="container d-flex justify-content-between">
              {currentPage > 1 && (
                <button
                  type="button"
                  className="btn btn-dark"
                  style={{
                    backgroundColor: '#1B2951',
                    color: 'white',
                  }}
                  onClick={handlePrevClick}
                >
                  Previous page
                </button>
              )}
              {currentPage < totalPages && (
                <button
                  type="button"
                  className="btn btn-success"
                  style={{
                    backgroundColor: '#89C000',
                    color: 'white',
                    border: 'none',
                  }}
                  onClick={handleNextClick}
                >
                  Next page
                </button>
              )}
            </div>
          )}
        </div>
      </MediaQuery>

      <MediaQuery maxWidth={1160}>
        <div className="container mt-3">
          <h1 className="text-center" style={{ fontSize: '30px' }}>Similar blogs</h1>
          <div className="row">
            {/* <div className="col-sm-12 col-md-12 col-lg-12 d-flex "> */}

              {paginatedPosts.map((post) => (
                    <div className="col-sm-12 col-md-6 col-lg-12 d-flex flex-column align-items-stretch ">
                    <div className="post mb-2" style={{width:'100%'}} key={post.id}>
                      <div className="card h-100 d-flex flex-column " >
                        <img
                          className="card-img-topMobile"
                          src={post.img}
                            style={{width:'-webkit-fill-avaiable', padding: '14px', borderRadius: '8px',objectFit:'cover',objectPosition:'top right' }}
                          alt={post.img.substring(26)}
                          />
                        <div className="card-body d-flex flex-column justify-content-end">
                          <h3 className="card-title  break-after-30ch" style={{ fontSize: '24px' }}>
                            {post.title}
                          </h3>
                          <Link to={`/post/${post.id}`}>
                            <button className="btn btn-sm button_mobile mt-2" onClick={handleLinkClick}>
                              Read More
                            </button>
                          </Link>
                        </div>
                      </div>
                    </div>
              </div>
              ))}
              {totalPages > 1 && (
                <div className="container d-flex justify-content-between">
                  {currentPage > 1 && (
                    <button
                      type="button"
                      className="btn btn-dark"
                      style={{
                        backgroundColor: '#1B2951',
                        color: 'white',
                      }}
                      onClick={handlePrevClick}
                    >
                      Previous page
                    </button>
                  )}
                  {currentPage < totalPages && (
                    <button
                      type="button"
                      className="btn btn-success"
                      style={{
                        backgroundColor: '#89C000',
                        color: 'white',
                        border: 'none',
                      }}
                      onClick={handleNextClick}
                    >
                      Next Page
                    </button>
                  )}
                </div>
              )}
            {/* </div> */}
          </div>
        </div>
      </MediaQuery>
    </>
  );
};

export default Menu;
