import React, { useEffect } from 'react';

export default function BtcBlog() {
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
  return (
<div style={{margin:'0px auto'}}>
	<iframe id="example1" src="https://deepreddy.shinyapps.io/BTC_network/" style={{border : 'none', width: '100%', height: '850px'}}  frameborder="1">
</iframe> 

</div>  
)}
