import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import axios from 'axios';
import { HeroSect } from './HeroSect';
import MediaQuery from 'react-responsive';
import LoadingBar from 'react-top-loading-bar';
// import InfiniteScroll from 'react-infinite-scroll-component';
// import moment from 'moment';
import moment1 from 'moment-timezone';
import btc from '../Assets/Img/BTC.png'
import pfl from '../Assets/Img/usa1.jpg'
import tabvsqlik from '../Assets/Img/tableuvsqlik1.jpg'
import fema from '../Assets/Img/FEMA.png'
import HelmetForTitle from '../components/HelmetForTitle';
import dpvsdm from '../Assets/Img/dpvsdm/PVSM-vec.png'
import dpvsdm_pdf from '../Assets/Img/dpvsdm/Document_Processing_VS_Document_Management.pdf'
// import BtcBlog from '../OldBlogs/BtcBlog';


export default function Home() {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const [posts, setPosts] = useState([]);
  // const [currentPage, setCurrentPage] = useState(1);
  // const pageSize = 8;

  // const handlePrevClick = () => {
  //   setCurrentPage((prevPage) => prevPage - 1);
  // };

  // const handleNextClick = () => {
  //   setCurrentPage((prevPage) => prevPage + 1);
  // };

  const cat = useLocation().search;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get(`/api/posts${cat}`);
        // console.log("First ",res.data);
        setPosts(res.data);
        // setGetting(false)
      } catch (err) {
        console.error(err);
      }
    };
    fetchData();
  }, [cat]);


  // useEffect(() => {
  //   fetch(`/posts${cat}`)
  //     .then((res) => res.json())
  //     .then((data) => setPosts(data));
  // }, [cat]);


// console.log('posts', posts)
  // const startIndex= (currentPage - 1) * pageSize;
  // const endIndex =currentPage * pageSize;
  // const paginatedPosts = posts.slice(startIndex,endIndex );
  const [isLoading, setIsLoading] = useState(false);
  const [loadingProgress, setLoadingProgress] = useState(0);
console.log(isLoading)
  const handleLinkClick = () => {
    setIsLoading(true);
    setLoadingProgress(30); // Set initial progress value

    // Simulate loading delay (remove this in your actual code)
    setTimeout(() => {
      setLoadingProgress(100); // Set progress to complete
      setIsLoading(false);

      // Reset progress after a delay (optional)
      // setTimeout(() => {
      //   setLoadingProgress(0);
      // }, 500);
    }, 1000);
  };

  // useEffect(() => {
  //   let maxWidth = 0;
  //   const cards = document.getElementsByClassName('card');
  //   for (let i = 0; i < cards.length; i++) {
  //     maxWidth = Math.max(maxWidth, cards[i].offsetWidth);
  //   }
  //   for (let i = 0; i < cards.length; i++) {
  //     cards[i].style.width = `${maxWidth}px`;
  //   }
  // }, [paginatedPosts]);

  // const fetchMoreData = () => {
  //   setTimeout(() => {
  //     setCurrentPage((prevPage) => prevPage + 1);
  //   }, 1500);
  // };
  


  return (
    <>
      <LoadingBar progress={loadingProgress} height={3} color="#1B2951" />
<HelmetForTitle/>
      <div>
        <HeroSect />
      </div>
      {/* <InfiniteScroll
        dataLength={paginatedPosts.length} //This is important field to render the next data
        next={fetchMoreData}
        hasMore={currentPage * pageSize < posts.length}
        loader={<h4>Loading...</h4>}
        endMessage={
          <p style={{ textAlign: 'center' }}>
            <b>Yay! You have seen it all</b>
          </p>
        }
      > */}

      {/* Laptop View */}
      <MediaQuery maxWidth={1920}>
       <MediaQuery minWidth={1160}>
          <div className="home1">
            <div className="posts1">
              <div className="container-fluid">
                <div className='section-title'>
                <h2>All</h2>
                <p>Blogs</p>
                </div>
              </div>
 

              <div className="container-fluid" style={{width:'100%',paddingLeft:'80px',paddingRight:'80px',marginRight:'auto',marginLeft:'auto'}}>
                <div className="row" style={{ display: 'flex' }}>

                <div className="col-sm-12 col-md-6 col-lg-3 d-flex align-items-stretch" style={{justifyContent:'center'}}>
                        <div className="post mb-4">
                <div className="card p-2 d-flex flex-column" style={{ height: '100%',width:'-webkit-fill-available' }}>
                            <img
                              className="card-img-top"
                              src={dpvsdm}
                              style={{ padding: '10px', borderRadius: '8px' }}
                              alt='BTC'
                            />
                            <div className="card-body d-flex flex-column flex-grow-1">
                              <h3 className="card-title font-weight-bold" style={{ fontSize: '18px',display:'inline-block'}}>
                              Document Processing Vs Document Management: Uncovering The Key Differences
                              </h3>
                              {/* <span className="text-muted" style={{ fontSize: '16px' }}>
                              {post.hashtag}                             </span> */}
                              <span className="text-muted mt-0 " style={{ fontSize: '12px' }}>
                                Author : Eigennet LLC</span>
                              <span className="text-muted mt-0 mb-2 " style={{ fontSize: '12px' }}>
                              {/* <p>Posted {moment1('2019-11-26 00:00:00', 'YYYY-MM-DD HH:mm:ss').tz('America/New_York').fromNow(true)} ago</p> */}
                                </span>
                              {/* <p dangerouslySetInnerHTML={{ __html: post.desc.substring(0,200) + '...' }} /> */}
                              <div className="mt-auto">
                              {/* <iframe src={dpvsdm_pdf} width="100%" height="500px">  <button className="btn btn-sm button_mobile" onClick={handleLinkClick}>
                                    Read More
                                  </button></iframe> */}
                                <Link to='/DocumentProcessingVsDocumentManagement' target='_blank' rel='noreferrer'>
                                  <button className="btn btn-sm button_mobile" onClick={handleLinkClick}>
                                    Read More
                                  </button>
                                </Link>
                              </div>
                            </div>
                          </div>
                          </div>
                          </div>
                  {/* {posts.length > 0 ? ( */}
                   { posts.map((post) => {
                      return(
                      <div className="col-sm-12 col-md-6 col-lg-3 d-flex align-items-stretch" style={{justifyContent:'center'}} key={post.id}>
                        <div className="post mb-4">
                          <div className="card p-2 d-flex flex-column" style={{ height: '100%',width:'-webkit-fill-available' }}>
                            <img
                              className="card-img-top"
                              // src={`./upload/${post.img}`}
                              src={post.img}
                              style={{ padding: '0px', borderRadius: '8px',objectFit:'cover',objectPosition:'right' }}
                              alt={String(post.img).substring(37)}
                            />
                            <div className="card-body d-flex flex-column flex-grow-1">
                              <h3 className="card-title font-weight-bold break-after-30ch" style={{ fontSize: '18px',display:'inline-block'}}>
                                {post.title}
                              </h3>
                              {/* <span className="text-muted" style={{ fontSize: '16px' }}>
                              {post.hashtag}                             </span> */}
                              <span className="text-muted mt-0 " style={{ fontSize: '12px' }}>
                                Author : {post.username}</span>
                              <span className="text-muted mt-0 mb-2 " style={{ fontSize: '12px' }}>
                              {/* Posted {moment1(post.date, 'YYYY-MM-DD HH:mm:ss').tz('America/New_York').fromNow(true)} ago                           */}
                                </span>
                              {/* <p dangerouslySetInnerHTML={{ __html: post.desc.substring(0,200) + '...' }} /> */}
                              <div className="mt-auto">
                                <Link to={`/post/${post.id}`}>
                                  <button className="btn btn-sm button_mobile" onClick={handleLinkClick}>
                                    Read More
                                  </button>
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      )
}) 
//   ) : (
//   <div className='container text-center' style={{fontSize:'20px'}}>No posts yet!</div>
// )
} 

<div className="col-sm-12 col-md-6 col-lg-3 d-flex align-items-stretch" style={{justifyContent:'center'}}>
                        <div className="post mb-4">
                <div className="card p-2 d-flex flex-column" style={{ height: '100%',width:'-webkit-fill-available' }}>
                            <img
                              className="card-img-top"
                              src={btc}
                              style={{ padding: '10px', borderRadius: '8px' }}
                              alt='BTC'
                            />
                            <div className="card-body d-flex flex-column flex-grow-1">
                              <h3 className="card-title font-weight-bold" style={{ fontSize: '18px',display:'inline-block'}}>
                              BTC Blockchain Network
                              </h3>
                              {/* <span className="text-muted" style={{ fontSize: '16px' }}>
                              {post.hashtag}                             </span> */}
                              <span className="text-muted mt-0 " style={{ fontSize: '12px' }}>
                                Author : Eigennet LLC</span>
                              <span className="text-muted mt-0 mb-2 " style={{ fontSize: '12px' }}>
                              {/* <p>Posted {moment1('2019-11-26 00:00:00', 'YYYY-MM-DD HH:mm:ss').tz('America/New_York').fromNow(true)} ago</p> */}
                                </span>
                              {/* <p dangerouslySetInnerHTML={{ __html: post.desc.substring(0,200) + '...' }} /> */}
                              <div className="mt-auto">
                                <Link to='/BtcBlog'>
                                  <button className="btn btn-sm button_mobile" onClick={handleLinkClick}>
                                    Read More
                                  </button>
                                </Link>
                              </div>
                            </div>
                          </div>
                          </div>
                          </div>

                          <div className="col-sm-12 col-md-6 col-lg-3 d-flex align-items-stretch" style={{justifyContent:'center'}}>
                        <div className="post mb-4">
                <div className="card p-2 d-flex flex-column" style={{ height: '100%',width:'-webkit-fill-available' }}>
                            <img
                              className="card-img-top"
                              src={fema}
                              style={{ padding: '10px', borderRadius: '8px' }}
                              alt='fema'
                            />
                            <div className="card-body d-flex flex-column flex-grow-1">
                              <h3 className="card-title font-weight-bold" style={{ fontSize: '18px',display:'inline-block'}}>
                              Demographics of FEMA Incidents
                              </h3>
                              {/* <span className="text-muted" style={{ fontSize: '16px' }}>
                              {post.hashtag}                             </span> */}
                              <span className="text-muted mt-0 " style={{ fontSize: '12px' }}>
                                Author : Eigennet LLC</span>
                              <span className="text-muted mt-0 mb-2 " style={{ fontSize: '12px' }}>
                              {/* <p>Posted {moment1('2019-01-22 00:00:00', 'YYYY-MM-DD HH:mm:ss').tz('America/New_York').fromNow(true)} ago</p> */}
                                </span>
                              {/* <p dangerouslySetInnerHTML={{ __html: post.desc.substring(0,200) + '...' }} /> */}
                              <div className="mt-auto">
                                <Link to='/FemaTableau'>
                                  <button className="btn btn-sm button_mobile" onClick={handleLinkClick}>
                                    Read More
                                  </button>
                                </Link>
                              </div>
                            </div>
                          </div>
                          </div>
                          </div>

                          <div className="col-sm-12 col-md-6 col-lg-3 d-flex align-items-stretch" style={{justifyContent:'center'}}>
                        <div className="post mb-4">
                <div className="card p-2 d-flex flex-column" style={{ height: '100%',width:'-webkit-fill-available' }}>
                            <img
                              className="card-img-top"
                              src={pfl}
                              style={{ padding: '10px', borderRadius: '8px' }}
                              alt='pfl'
                            />
                            <div className="card-body d-flex flex-column flex-grow-1">
                              <h3 className="card-title font-weight-bold" style={{ fontSize: '18px',display:'inline-block'}}>
                              PFL Benefits by State                              </h3>
                              {/* <span className="text-muted" style={{ fontSize: '16px' }}>
                              {post.hashtag}                             </span> */}
                              <span className="text-muted mt-0 " style={{ fontSize: '12px' }}>
                                Author : Eigennet LLC</span>
                              <span className="text-muted mt-0 mb-2 " style={{ fontSize: '12px' }}>
                              {/* <p>Posted {moment1('2019-11-27 00:00:00', 'YYYY-MM-DD HH:mm:ss').tz('America/New_York').fromNow(true)} ago</p> */}
                                </span>
                              {/* <p dangerouslySetInnerHTML={{ __html: post.desc.substring(0,200) + '...' }} /> */}
                              <div className="mt-auto">
                                <Link to='/Tableau'>
                                  <button className="btn btn-sm button_mobile" onClick={handleLinkClick}>
                                    Read More
                                  </button>
                                </Link>
                              </div>
                            </div>
                          </div>
                          </div>
                          </div>


                          <div className="col-sm-12 col-md-6 col-lg-3 d-flex align-items-stretch" style={{justifyContent:'center'}}>
                        <div className="post mb-4">
                <div className="card p-2 d-flex flex-column" style={{ height: '100%',width:'-webkit-fill-available' }}>
                            <img
                              className="card-img-top"
                              src={tabvsqlik}
                              style={{ padding: '10px', borderRadius: '8px' }}
                              alt='tabvsqlik'
                            />
                            <div className="card-body d-flex flex-column flex-grow-1">
                              <h3 className="card-title font-weight-bold" style={{ fontSize: '18px',display:'inline-block'}}>
                              Tableau VS. Qlik VS. Power BI                             </h3>
                              {/* <span className="text-muted" style={{ fontSize: '16px' }}>
                              {post.hashtag}                             </span> */}
                              <span className="text-muted mt-0 " style={{ fontSize: '12px' }}>
                                Author : Eigennet LLC</span>
                              <span className="text-muted mt-0 mb-2 " style={{ fontSize: '12px' }}>
                              {/* <p>Posted {moment1('2019-09-26 00:00:00', 'YYYY-MM-DD HH:mm:ss').tz('America/New_York').fromNow(true)} ago</p> */}
                                </span>
                              {/* <p dangerouslySetInnerHTML={{ __html: post.desc.substring(0,200) + '...' }} /> */}
                              <div className="mt-auto">
                                <Link to='/TableauVsQlik'>
                                  <button className="btn btn-sm button_mobile" onClick={handleLinkClick}>
                                    Read More
                                  </button>
                                </Link>
                              </div>
                            </div>
                          </div>
                          </div>
                          </div>


                </div>

{/* 
                <div className="row">
                <div className="col-sm-12 col-md-6 col-lg-3 d-flex align-items-stretch" style={{justifyContent:'center'}}>
                        <div className="post mb-4">
                <div className="card p-2 d-flex flex-column" style={{ height: '100%',width:'-webkit-fill-available' }}>
                            <img
                              className="card-img-top"
                              src={btc}
                              style={{ padding: '14px', borderRadius: '8px' }}
                              alt='BTC'
                            />
                            <div className="card-body d-flex flex-column flex-grow-1">
                              <h3 className="card-title font-weight-bold" style={{ fontSize: '18px',display:'inline-block'}}>
                              BTC Blockchain Network
                              </h3>
                              <span className="text-muted mt-0 " style={{ fontSize: '12px' }}>
                                Author : Eigennet LLC</span>
                              <div className="mt-auto">
                                <Link to='/BtcBlog'>
                                  <button className="btn btn-sm button_mobile" onClick={handleLinkClick}>
                                    Read More
                                  </button>
                                </Link>
                              </div>
                            </div>
                          </div>
                          </div>
                          </div>

                          </div> */}
                
              </div>
            </div>
{/* 
            <div className="container d-flex justify-content-between">
              <button
                type="button"
                className="btn btn-dark"
                style={{
                  backgroundColor: '#1B2951',
                  color: 'white',
                }}
                onClick={handlePrevClick}
                disabled={currentPage === 1}
              >
                Previous
              </button>
              <button
                type="button"
                className="btn btn-success"
                style={{
                  backgroundColor: '#89C000',
                  color: 'white',
                  border: 'none',
                }}
                onClick={handleNextClick}
                disabled={currentPage * pageSize >= posts.length}
              >
                Next
              </button>
            </div> */}
          </div>
        </MediaQuery>
        </MediaQuery>
      {/* </InfiniteScroll> */}

{/* For Ultra wide screen */}
<MediaQuery minWidth={1921}>
<div className="home1">
            <div className="posts1">
              <div className="container section-titleUltrawide">
                <h2>All</h2>
                <p>Blogs</p>
              </div>

              <div className="container">
                <div className="row" style={{ display: 'flex' }}>

                <div className="col-sm-12 col-md-6 col-lg-3 d-flex align-items-stretch" style={{justifyContent:'center'}}>
                        <div className="post mb-4">
                <div className="card p-2 d-flex flex-column" style={{ height: '100%',width:'100%' }}>
                            <img
                              className="card-img-top"
                              src={dpvsdm}
                              style={{ padding: '10px', borderRadius: '8px',height:'15em',width:'15em'  }}
                              alt='Document Processing Vs Document Management: Uncovering The Key Differences'
                            />
                            <div className="card-body d-flex flex-column flex-grow-1">
                              <h3 className="card-title font-weight-bold" style={{ fontSize: '18px',display:'inline-block'}}>
                              Document Processing Vs Document Management: Uncovering The Key Differences
                              </h3>
                              {/* <span className="text-muted" style={{ fontSize: '16px' }}>
                              {post.hashtag}                             </span> */}
                              <span className="text-muted mt-0 " style={{ fontSize: '12px' }}>
                                Author : Eigennet LLC</span>
                              <span className="text-muted mt-0 mb-2 " style={{ fontSize: '12px' }}>
                              {/* <p>Posted {moment1('2019-11-26 00:00:00', 'YYYY-MM-DD HH:mm:ss').tz('America/New_York').fromNow(true)} ago</p> */}
                                </span>
                              {/* <p dangerouslySetInnerHTML={{ __html: post.desc.substring(0,200) + '...' }} /> */}
                              <div className="mt-auto">
                                {/* <a href={dpvsdm_pdf} target='_blank' rel='noreferrer'>
                                  <button className="btn btn-sm button_mobile" onClick={handleLinkClick}>
                                    Read More
                                  </button>
                                </a> */}
                                   <Link to="/DocumentProcessingVsDocumentManagement" target='_blank' rel='noreferrer'>
                                  <button className="btn btn-sm button_mobile" onClick={handleLinkClick}>
                                    Read More
                                  </button>
                                </Link>
                              </div>
                            </div>
                          </div>
                          </div>
                          </div>

                  {/* {paginatedPosts.length > 0 ? ( */}
                    {posts.map((post) => {
                      return(
                      <div className="col-sm-12 col-md-6 col-lg-3 d-flex align-items-stretch" style={{justifyContent:'center'}} key={post.id}>
                        <div className="post mb-4">
                          <div className="card p-2 d-flex flex-column" style={{ height: '100%',width:'-webkit-fill-available' }}>
                            <img
                              className="card-img-top"
                              src={post.img}
                              style={{ padding: '0px', borderRadius: '8px',objectFit:'cover',objectPosition:'top right',height:'11em',width:'14em' }}
                              alt={post.img}
                            />
                            <div className="card-body d-flex flex-column flex-grow-1">
                              <h3 className="card-title font-weight-bold break-after-30ch" style={{ fontSize: '18px' }}>
                                {post.title}
                              </h3>
                              {/* <span className="text-muted" style={{ fontSize: '16px' }}>
                              {post.hashtag}                             </span> */}
                              <span className="text-muted mt-0 " style={{ fontSize: '12px' }}>
                                Author : {post.username}</span>
                              {/* <span className="text-muted mt-0 mb-2 " style={{ fontSize: '12px' }}>
                              Posted {moment1(post.date, 'YYYY-MM-DD HH:mm:ss').tz('America/New_York').fromNow(true)} ago                          
                                  </span> */}
   
                              {/* <p dangerouslySetInnerHTML={{ __html: post.desc.substring(0,200) + '...' }} /> */}
                              <div className="mt-auto">
                                <Link to={`/post/${post.id}`}>
                                  <button className="btn btn-sm button_mobile" onClick={handleLinkClick}>
                                    Read More
                                  </button>
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      )
}) 
//  ) : (
//   <p>No posts yet!</p>
// )} 
}     

<div className="col-sm-12 col-md-6 col-lg-3 d-flex align-items-stretch" style={{justifyContent:'center'}}>
                        <div className="post mb-4">
                <div className="card p-2 d-flex flex-column" style={{ height: '100%',width:'100%' }}>
                            <img
                              className="card-img-topDefined"
                              src={btc}
                              style={{ padding: '10px', borderRadius: '8px',height:'15em',width:'15em' }}
                              alt='BTC'
                            />
                            <div className="card-body d-flex flex-column flex-grow-1">
                              <h3 className="card-title font-weight-bold" style={{ fontSize: '18px',display:'inline-block'}}>
                              BTC Blockchain Network
                              </h3>
                              {/* <span className="text-muted" style={{ fontSize: '16px' }}>
                              {post.hashtag}                             </span> */}
                              <span className="text-muted mt-0 " style={{ fontSize: '12px' }}>
                                Author : Eigennet LLC</span>
                              {/* <span className="text-muted mt-0 mb-2 " style={{ fontSize: '12px' }}>
                              <p>Posted {moment1('2019-01-22 00:00:00', 'YYYY-MM-DD HH:mm:ss').tz('America/New_York').fromNow(true)} ago</p>
                                </span> */}
                              {/* <p dangerouslySetInnerHTML={{ __html: post.desc.substring(0,200) + '...' }} /> */}
                              <div className="mt-auto">
                                <Link to='/BtcBlog'>
                                  <button className="btn btn-sm button_mobile" onClick={handleLinkClick}>
                                    Read More
                                  </button>
                                </Link>
                              </div>
                            </div>
                          </div>
                          </div>
                          </div>

                          <div className="col-sm-12 col-md-6 col-lg-3 d-flex align-items-stretch" style={{justifyContent:'center'}}>
                        <div className="post mb-4">
                <div className="card p-2 d-flex flex-column" style={{ height: '100%',width:'100%' }}>
                            <img
                              className="card-img-topDefined"
                              src={fema}
                              style={{ padding: '10px', borderRadius: '8px',height:'15em',width:'15em'  }}
                              alt='fema'
                            />
                            <div className="card-body d-flex flex-column flex-grow-1">
                              <h3 className="card-title font-weight-bold" style={{ fontSize: '18px',display:'inline-block'}}>
                              Demographics of FEMA Incidents
                              </h3>
                              {/* <span className="text-muted" style={{ fontSize: '16px' }}>
                              {post.hashtag}                             </span> */}
                              <span className="text-muted mt-0 " style={{ fontSize: '12px' }}>
                                Author : Eigennet LLC</span>
                              {/* <span className="text-muted mt-0 mb-2 " style={{ fontSize: '12px' }}>
                              <p>Posted {moment1('2019-01-22 00:00:00', 'YYYY-MM-DD HH:mm:ss').tz('America/New_York').fromNow(true)} ago</p>
                                </span> */}
                              {/* <p dangerouslySetInnerHTML={{ __html: post.desc.substring(0,200) + '...' }} /> */}
                              <div className="mt-auto">
                                <Link to='/FemaTableau'>
                                  <button className="btn btn-sm button_mobile" onClick={handleLinkClick}>
                                    Read More
                                  </button>
                                </Link>
                              </div>
                            </div>
                          </div>
                          </div>
                          </div>

                          <div className="col-sm-12 col-md-6 col-lg-3 d-flex align-items-stretch" style={{justifyContent:'center'}}>
                        <div className="post mb-4">
                <div className="card p-2 d-flex flex-column" style={{ height: '100%',width:'100%' }}>
                            <img
                              className="card-img-topDefined"
                              src={pfl}
                              style={{ padding: '10px', borderRadius: '8px',height:'15em',width:'15em'  }}
                              alt='pfl'
                            />
                            <div className="card-body d-flex flex-column flex-grow-1">
                              <h3 className="card-title font-weight-bold" style={{ fontSize: '18px',display:'inline-block'}}>
                              PFL Benefits by State                              </h3>
                              {/* <span className="text-muted" style={{ fontSize: '16px' }}>
                              {post.hashtag}                             </span> */}
                              <span className="text-muted mt-0 " style={{ fontSize: '12px' }}>
                                Author : Eigennet LLC</span>
                              {/* <span className="text-muted mt-0 mb-2 " style={{ fontSize: '12px' }}>
                              <p>Posted {moment1('2019-11-27 00:00:00', 'YYYY-MM-DD HH:mm:ss').tz('America/New_York').fromNow(true)} ago</p>
                                </span> */}
                              {/* <p dangerouslySetInnerHTML={{ __html: post.desc.substring(0,200) + '...' }} /> */}
                              <div className="mt-auto">
                                <Link to='/Tableau'>
                                  <button className="btn btn-sm button_mobile" onClick={handleLinkClick}>
                                    Read More
                                  </button>
                                </Link>
                              </div>
                            </div>
                          </div>
                          </div>
                          </div>


                          <div className="col-sm-12 col-md-6 col-lg-3 d-flex align-items-stretch" style={{justifyContent:'center'}}>
                        <div className="post mb-4">
                <div className="card p-2 d-flex flex-column" style={{ height: '100%',width:'100%' }}>
                            <img
                              className="card-img-topDefined"
                              src={tabvsqlik}
                              style={{ padding: '10px', borderRadius: '8px',height:'15em',width:'15em'  }}
                              alt='tabvsqlik'
                            />
                            <div className="card-body d-flex flex-column flex-grow-1">
                              <h3 className="card-title font-weight-bold" style={{ fontSize: '18px',display:'inline-block'}}>
                              Tableau VS. Qlik VS. Power BI                             </h3>
                              {/* <span className="text-muted" style={{ fontSize: '16px' }}>
                              {post.hashtag}                             </span> */}
                              <span className="text-muted mt-0 " style={{ fontSize: '12px' }}>
                                Author : Eigennet LLC</span>
                              {/* <span className="text-muted mt-0 mb-2 " style={{ fontSize: '12px' }}>
                              <p>Posted {moment1('2019-09-26 00:00:00', 'YYYY-MM-DD HH:mm:ss').tz('America/New_York').fromNow(true)} ago</p>
                                </span> */}
                              {/* <p dangerouslySetInnerHTML={{ __html: post.desc.substring(0,200) + '...' }} /> */}
                              <div className="mt-auto">
                                <Link to='/TableauVsQlik'>
                                  <button className="btn btn-sm button_mobile" onClick={handleLinkClick}>
                                    Read More
                                  </button>
                                </Link>
                              </div>
                            </div>
                          </div>
                          </div>
                          </div>


                </div>
              </div>
            </div>
{/* 
            <div className="container d-flex justify-content-between">
              <button
                type="button"
                className="btn btn-dark"
                style={{
                  backgroundColor: '#1B2951',
                  color: 'white',
                }}
                onClick={handlePrevClick}
                disabled={currentPage === 1}
              >
                Previous
              </button>
              <button
                type="button"
                className="btn btn-success"
                style={{
                  backgroundColor: '#89C000',
                  color: 'white',
                  border: 'none',
                }}
                onClick={handleNextClick}
                disabled={currentPage * pageSize >= posts.length}
              >
                Next
              </button>
            </div> */}
          </div>

</MediaQuery>


{/* Tab or ipad view */}
      <MediaQuery  minWidth={768} maxWidth={1159}>
        <div className="home1">
          <div className="posts1">
            <div className="container section-titleMobile pb-0">
              <h2>All</h2>
              <p>Blogs</p>
            </div>

            <div className="container">
              <div className="row">

              <div className="col-sm-12 col-md-6 col-lg-3 d-flex align-items-stretch" style={{justifyContent:'center'}}>
                        <div className="post mb-4">
                <div className="card p-2 d-flex flex-column" style={{ height: '100%',width:'-webkit-fill-available' }}>
                            <img
                              className="card-img-top"
                              src={dpvsdm}
                              style={{ padding: '10px', borderRadius: '8px' }}
                              alt='BTC'
                            />
                            <div className="card-body d-flex flex-column flex-grow-1">
                              <h3 className="card-title font-weight-bold" style={{ fontSize: '18px',display:'inline-block'}}>
                              Document Processing Vs Document Management: Uncovering The Key Differences
                              </h3>
                              {/* <span className="text-muted" style={{ fontSize: '16px' }}>
                              {post.hashtag}                             </span> */}
                              <span className="text-muted mt-0 " style={{ fontSize: '12px' }}>
                                Author : Eigennet LLC</span>
                              <span className="text-muted mt-0 mb-2 " style={{ fontSize: '12px' }}>
                              {/* <p>Posted {moment1('2019-11-26 00:00:00', 'YYYY-MM-DD HH:mm:ss').tz('America/New_York').fromNow(true)} ago</p> */}
                                </span>
                              {/* <p dangerouslySetInnerHTML={{ __html: post.desc.substring(0,200) + '...' }} /> */}
                              <div className="mt-auto">
                                {/* <a href={dpvsdm_pdf} target='_blank' rel='noreferrer'>
                                  <button className="btn btn-sm button_mobile" onClick={handleLinkClick}>
                                    Read More
                                  </button>
                                </a> */}
                                   <Link to="/DocumentProcessingVsDocumentManagement" target='_blank' rel='noreferrer'>
                                  <button className="btn btn-sm button_mobile" onClick={handleLinkClick}>
                                    Read More
                                  </button>
                                </Link>
                              </div>
                            </div>
                          </div>
                          </div>
                          </div>
                  {/* {posts.length > 0 ? ( */}
                   { posts.map((post) => (
                      <div className="col-sm-12 col-md-6 col-lg-6 d-flex align-items-stretch ">
                      <div className="post mb-2" style={{width:'100%'}} key={post.id}>
                        <div className="card h-100 d-flex flex-column " >
                          <img
                            className="card-img-topMobile"
                            src={post.img}
                              style={{width:'-webkit-fill-avaiable', padding: '0px', borderRadius: '8px',objectFit:'cover',objectPosition:'right' }}
                            alt={post.img}
                            />
                          <div className="card-body d-flex flex-column justify-content-end">
                            <h3 className="card-title font-weight-bold break-after-30ch" style={{ fontSize: '18px' }}>
                              {post.title}
                            </h3>
                            <span className="text-muted mt-0 " style={{ fontSize: '12px' }}>
                                Author : {post.username}</span>
                              {/* <span className="text-muted mt-0 mb-2 " style={{ fontSize: '12px' }}>
                              Posted {moment1(post.date, 'YYYY-MM-DD HH:mm:ss').tz('America/New_York').fromNow(true)} ago</span> */}
                            {/* <p dangerouslySetInnerHTML={{ __html: post.desc.substring(0, 200) + '...' }} /> */}
                            <Link to={`/post/${post.id}`}>
                              <button className="btn btn-sm button_mobile mt-2" onClick={handleLinkClick}>
                                Read More
                              </button>
                            </Link>
                          </div>
                        </div>
                      </div>
                </div>
                    ))
                  // ) : (
                  //   <p>No posts yet!</p>
                  // )
                  }

<div className="col-sm-12 col-md-6 col-lg-6 d-flex align-items-stretch" style={{justifyContent:'center'}}>
                        <div className="post mb-4" style={{width:'100%'}}>
                <div className="card p-2 d-flex flex-column" style={{ height: '100%',width:'-webkit-fill-available' }}>
                            <img
                              className="card-img-top"
                              src={btc}
                              style={{ padding: '10px', borderRadius: '8px' }}
                              alt='BTC'
                            />
                            <div className="card-body d-flex flex-column flex-grow-1">
                              <h3 className="card-title font-weight-bold" style={{ fontSize: '18px',display:'inline-block'}}>
                              BTC Blockchain Network
                              </h3>
                              {/* <span className="text-muted" style={{ fontSize: '16px' }}>
                              {post.hashtag}                             </span> */}
                              <span className="text-muted mt-0 " style={{ fontSize: '12px' }}>
                                Author : Eigennet LLC</span>
                              {/* <span className="text-muted mt-0 mb-2 " style={{ fontSize: '12px' }}>
                              <p>Posted {moment1('2019-12-26 00:00:00', 'YYYY-MM-DD HH:mm:ss').tz('America/New_York').fromNow(true)} ago</p>
                                </span> */}
                              {/* <p dangerouslySetInnerHTML={{ __html: post.desc.substring(0,200) + '...' }} /> */}
                              <div className="mt-auto">
                                <Link to='/BtcBlog'>
                                  <button className="btn btn-sm button_mobile" onClick={handleLinkClick}>
                                    Read More
                                  </button>
                                </Link>
                              </div>
                            </div>
                          </div>
                          </div>
                          </div>

                          <div className="col-sm-12 col-md-6 col-lg-6 d-flex align-items-stretch" style={{justifyContent:'center'}}>
                        <div className="post mb-4" style={{width:'100%'}}>
                <div className="card p-2 d-flex flex-column" style={{ height: '100%',width:'-webkit-fill-available' }}>
                            <img
                              className="card-img-top"
                              src={fema}
                              style={{ padding: '10px', borderRadius: '8px' }}
                              alt='fema'
                            />
                            <div className="card-body d-flex flex-column flex-grow-1">
                              <h3 className="card-title font-weight-bold" style={{ fontSize: '18px',display:'inline-block'}}>
                              Demographics of FEMA Incidents
                              </h3>
                              {/* <span className="text-muted" style={{ fontSize: '16px' }}>
                              {post.hashtag}                             </span> */}
                              <span className="text-muted mt-0 " style={{ fontSize: '12px' }}>
                                Author : Eigennet LLC</span>
                              {/* <span className="text-muted mt-0 mb-2 " style={{ fontSize: '12px' }}>
                              <p>Posted {moment1('2019-01-22 00:00:00', 'YYYY-MM-DD HH:mm:ss').tz('America/New_York').fromNow(true)} ago</p>
                                </span> */}
                              {/* <p dangerouslySetInnerHTML={{ __html: post.desc.substring(0,200) + '...' }} /> */}
                              <div className="mt-auto">
                                <Link to='/FemaTableau'>
                                  <button className="btn btn-sm button_mobile" onClick={handleLinkClick}>
                                    Read More
                                  </button>
                                </Link>
                              </div>
                            </div>
                          </div>
                          </div>
                          </div>

                          <div className="col-sm-12 col-md-6 col-lg-6 d-flex align-items-stretch" style={{justifyContent:'center'}}>
                        <div className="post mb-4" style={{width:'100%'}}>
                <div className="card p-2 d-flex flex-column" style={{ height: '100%',width:'-webkit-fill-available' }}>
                            <img
                              className="card-img-top"
                              src={pfl}
                              style={{ padding: '10px', borderRadius: '8px' }}
                              alt='pfl'
                            />
                            <div className="card-body d-flex flex-column flex-grow-1">
                              <h3 className="card-title font-weight-bold" style={{ fontSize: '18px',display:'inline-block'}}>
                              PFL Benefits by State                              </h3>
                              {/* <span className="text-muted" style={{ fontSize: '16px' }}>
                              {post.hashtag}                             </span> */}
                              <span className="text-muted mt-0 " style={{ fontSize: '12px' }}>
                                Author : Eigennet LLC</span>
                              {/* <span className="text-muted mt-0 mb-2 " style={{ fontSize: '12px' }}>
                              <p>Posted {moment1('2019-11-27 00:00:00', 'YYYY-MM-DD HH:mm:ss').tz('America/New_York').fromNow(true)} ago</p>
                                </span> */}
                              {/* <p dangerouslySetInnerHTML={{ __html: post.desc.substring(0,200) + '...' }} /> */}
                              <div className="mt-auto">
                                <Link to='/Tableau'>
                                  <button className="btn btn-sm button_mobile" onClick={handleLinkClick}>
                                    Read More
                                  </button>
                                </Link>
                              </div>
                            </div>
                          </div>
                          </div>
                          </div>

                          <div className="col-sm-12 col-md-6 col-lg-6 d-flex align-items-stretch" style={{justifyContent:'center'}}>
                        <div className="post mb-4" style={{width:'100%'}}>
                <div className="card p-2 d-flex flex-column" style={{ height: '100%',width:'-webkit-fill-available' }}>
                            <img
                              className="card-img-top"
                              src={tabvsqlik}
                              style={{ padding: '10px', borderRadius: '8px' }}
                              alt='tabvsqlik'
                            />
                            <div className="card-body d-flex flex-column flex-grow-1">
                              <h3 className="card-title font-weight-bold" style={{ fontSize: '18px',display:'inline-block'}}>
                              Tableau VS. Qlik VS. Power BI                             </h3>
                              {/* <span className="text-muted" style={{ fontSize: '16px' }}>
                              {post.hashtag}                             </span> */}
                              <span className="text-muted mt-0 " style={{ fontSize: '12px' }}>
                                Author : Eigennet LLC</span>
                              {/* <span className="text-muted mt-0 mb-2 " style={{ fontSize: '12px' }}>
                              <p>Posted {moment1('2019-09-26 00:00:00', 'YYYY-MM-DD HH:mm:ss').tz('America/New_York').fromNow(true)} ago</p>
                                </span> */}
                              {/* <p dangerouslySetInnerHTML={{ __html: post.desc.substring(0,200) + '...' }} /> */}
                              <div className="mt-auto">
                                <Link to='/TableauVsQlik'>
                                  <button className="btn btn-sm button_mobile" onClick={handleLinkClick}>
                                    Read More
                                  </button>
                                </Link>
                              </div>
                            </div>
                          </div>
                          </div>
                          </div>

              </div>
            </div>
            {/* <div className="container d-flex justify-content-between">
              <button
                type="button"
                className="btn btn-dark"
                style={{
                  backgroundColor: '#1B2951',
                  color: 'white',
                }}
                onClick={handlePrevClick}
                disabled={currentPage === 1}
              >
                Previous
              </button>
              <button
                type="button"
                className="btn btn-success"
                style={{
                  backgroundColor: '#89C000',
                  color: 'white',
                  border: 'none',
                }}
                onClick={handleNextClick}
                disabled={currentPage * pageSize >= posts.length}
              >
                Next
              </button>
            </div> */}
          </div>
        </div>
      </MediaQuery>

      {/* Mobile view */}
      <MediaQuery maxWidth={767}>
        <div className="home1">
          <div className="posts1">
            <div className="container section-titleMobile pb-0">
              <h2>All</h2>
              <p>Blogs</p>
            </div>

            <div className="container">
              <div className="row">

              <div className="col-sm-12 col-md-6 col-lg-3 d-flex align-items-stretch" style={{justifyContent:'center'}}>
                        <div className="post mb-4">
                <div className="card p-2 d-flex flex-column" style={{ height: '100%',width:'-webkit-fill-available' }}>
                            <img
                              className="card-img-top"
                              src={dpvsdm}
                              style={{ padding: '10px', borderRadius: '8px' }}
                              alt='BTC'
                            />
                            <div className="card-body d-flex flex-column flex-grow-1">
                              <h3 className="card-title font-weight-bold" style={{ fontSize: '18px',display:'inline-block'}}>
                              Document Processing Vs Document Management: Uncovering The Key Differences
                              </h3>
                              {/* <span className="text-muted" style={{ fontSize: '16px' }}>
                              {post.hashtag}                             </span> */}
                              <span className="text-muted mt-0 " style={{ fontSize: '12px' }}>
                                Author : Eigennet LLC</span>
                              <span className="text-muted mt-0 mb-2 " style={{ fontSize: '12px' }}>
                              {/* <p>Posted {moment1('2019-11-26 00:00:00', 'YYYY-MM-DD HH:mm:ss').tz('America/New_York').fromNow(true)} ago</p> */}
                                </span>
                              {/* <p dangerouslySetInnerHTML={{ __html: post.desc.substring(0,200) + '...' }} /> */}
                              <div className="mt-auto">
                                {/* <a href={dpvsdm_pdf} target='_blank' rel='noreferrer'>
                                  <button className="btn btn-sm button_mobile" onClick={handleLinkClick}>
                                    Read More
                                  </button>
                                </a> */}
                                   <Link to="/DocumentProcessingVsDocumentManagement" target='_blank' rel='noreferrer'>
                                  <button className="btn btn-sm button_mobile" onClick={handleLinkClick}>
                                    Read More
                                  </button>
                                </Link>
                              </div>
                            </div>
                          </div>
                          </div>
                          </div>
                  {/* {posts.length > 0 ? ( */}
                   { posts.map((post) => (
                      <div className="col-sm-12 col-md-6 col-lg-6 d-flex align-items-stretch ">
                      <div className="post mb-2" style={{width:'100%'}} key={post.id}>
                        <div className="card h-100 d-flex flex-column " >
                          <img
                            className="card-img-topMobile"
                            src={post.img}
                              style={{width:'-webkit-fill-avaiable', padding: '0px', borderRadius: '8px',objectFit:'cover',objectPosition:'right',padding:'0px 18px 0px 20px' }}
                            alt={post.img}
                            />
                          <div className="card-body d-flex flex-column justify-content-end">
                            <h3 className="card-title font-weight-bold break-after-30ch" style={{ fontSize: '18px' }}>
                              {post.title}
                            </h3>
                            <span className="text-muted mt-0 " style={{ fontSize: '12px' }}>
                                Author : {post.username}</span>
                              {/* <span className="text-muted mt-0 mb-2 " style={{ fontSize: '12px' }}>
                              Posted {moment1(post.date, 'YYYY-MM-DD HH:mm:ss').tz('America/New_York').fromNow(true)} ago</span> */}
                            {/* <p dangerouslySetInnerHTML={{ __html: post.desc.substring(0, 200) + '...' }} /> */}
                            <Link to={`/post/${post.id}`}>
                              <button className="btn btn-sm button_mobile mt-2" onClick={handleLinkClick}>
                                Read More
                              </button>
                            </Link>
                          </div>
                        </div>
                      </div>
                </div>
                    ))
                  // ) : (
                  //   <p>No posts yet!</p>
                  // )
                  }

<div className="col-sm-12 col-md-6 col-lg-6 d-flex align-items-stretch" style={{justifyContent:'center'}}>
                        <div className="post mb-4" style={{width:'100%'}}>
                <div className="card p-2 d-flex flex-column" style={{ height: '100%',width:'-webkit-fill-available' }}>
                            <img
                              className="card-img-top"
                              src={btc}
                              style={{ padding: '10px', borderRadius: '8px' }}
                              alt='BTC'
                            />
                            <div className="card-body d-flex flex-column flex-grow-1">
                              <h3 className="card-title font-weight-bold" style={{ fontSize: '18px',display:'inline-block'}}>
                              BTC Blockchain Network
                              </h3>
                              {/* <span className="text-muted" style={{ fontSize: '16px' }}>
                              {post.hashtag}                             </span> */}
                              <span className="text-muted mt-0 " style={{ fontSize: '12px' }}>
                                Author : Eigennet LLC</span>
                              {/* <span className="text-muted mt-0 mb-2 " style={{ fontSize: '12px' }}>
                              <p>Posted {moment1('2019-12-26 00:00:00', 'YYYY-MM-DD HH:mm:ss').tz('America/New_York').fromNow(true)} ago</p>
                                </span> */}
                              {/* <p dangerouslySetInnerHTML={{ __html: post.desc.substring(0,200) + '...' }} /> */}
                              <div className="mt-auto">
                                <Link to='/BtcBlog'>
                                  <button className="btn btn-sm button_mobile" onClick={handleLinkClick}>
                                    Read More
                                  </button>
                                </Link>
                              </div>
                            </div>
                          </div>
                          </div>
                          </div>

                          <div className="col-sm-12 col-md-6 col-lg-6 d-flex align-items-stretch" style={{justifyContent:'center'}}>
                        <div className="post mb-4" style={{width:'100%'}}>
                <div className="card p-2 d-flex flex-column" style={{ height: '100%',width:'-webkit-fill-available' }}>
                            <img
                              className="card-img-top"
                              src={fema}
                              style={{ padding: '10px', borderRadius: '8px' }}
                              alt='fema'
                            />
                            <div className="card-body d-flex flex-column flex-grow-1">
                              <h3 className="card-title font-weight-bold" style={{ fontSize: '18px',display:'inline-block'}}>
                              Demographics of FEMA Incidents
                              </h3>
                              {/* <span className="text-muted" style={{ fontSize: '16px' }}>
                              {post.hashtag}                             </span> */}
                              <span className="text-muted mt-0 " style={{ fontSize: '12px' }}>
                                Author : Eigennet LLC</span>
                              {/* <span className="text-muted mt-0 mb-2 " style={{ fontSize: '12px' }}>
                              <p>Posted {moment1('2019-01-22 00:00:00', 'YYYY-MM-DD HH:mm:ss').tz('America/New_York').fromNow(true)} ago</p>
                                </span> */}
                              {/* <p dangerouslySetInnerHTML={{ __html: post.desc.substring(0,200) + '...' }} /> */}
                              <div className="mt-auto">
                                <Link to='/FemaTableau'>
                                  <button className="btn btn-sm button_mobile" onClick={handleLinkClick}>
                                    Read More
                                  </button>
                                </Link>
                              </div>
                            </div>
                          </div>
                          </div>
                          </div>

                          <div className="col-sm-12 col-md-6 col-lg-6 d-flex align-items-stretch" style={{justifyContent:'center'}}>
                        <div className="post mb-4" style={{width:'100%'}}>
                <div className="card p-2 d-flex flex-column" style={{ height: '100%',width:'-webkit-fill-available' }}>
                            <img
                              className="card-img-top"
                              src={pfl}
                              style={{ padding: '10px', borderRadius: '8px' }}
                              alt='pfl'
                            />
                            <div className="card-body d-flex flex-column flex-grow-1">
                              <h3 className="card-title font-weight-bold" style={{ fontSize: '18px',display:'inline-block'}}>
                              PFL Benefits by State                              </h3>
                              {/* <span className="text-muted" style={{ fontSize: '16px' }}>
                              {post.hashtag}                             </span> */}
                              <span className="text-muted mt-0 " style={{ fontSize: '12px' }}>
                                Author : Eigennet LLC</span>
                              {/* <span className="text-muted mt-0 mb-2 " style={{ fontSize: '12px' }}>
                              <p>Posted {moment1('2019-11-27 00:00:00', 'YYYY-MM-DD HH:mm:ss').tz('America/New_York').fromNow(true)} ago</p>
                                </span> */}
                              {/* <p dangerouslySetInnerHTML={{ __html: post.desc.substring(0,200) + '...' }} /> */}
                              <div className="mt-auto">
                                <Link to='/Tableau'>
                                  <button className="btn btn-sm button_mobile" onClick={handleLinkClick}>
                                    Read More
                                  </button>
                                </Link>
                              </div>
                            </div>
                          </div>
                          </div>
                          </div>

                          <div className="col-sm-12 col-md-6 col-lg-6 d-flex align-items-stretch" style={{justifyContent:'center'}}>
                        <div className="post mb-4" style={{width:'100%'}}>
                <div className="card p-2 d-flex flex-column" style={{ height: '100%',width:'-webkit-fill-available' }}>
                            <img
                              className="card-img-top"
                              src={tabvsqlik}
                              style={{ padding: '10px', borderRadius: '8px' }}
                              alt='tabvsqlik'
                            />
                            <div className="card-body d-flex flex-column flex-grow-1">
                              <h3 className="card-title font-weight-bold" style={{ fontSize: '18px',display:'inline-block'}}>
                              Tableau VS. Qlik VS. Power BI                             </h3>
                              {/* <span className="text-muted" style={{ fontSize: '16px' }}>
                              {post.hashtag}                             </span> */}
                              <span className="text-muted mt-0 " style={{ fontSize: '12px' }}>
                                Author : Eigennet LLC</span>
                              {/* <span className="text-muted mt-0 mb-2 " style={{ fontSize: '12px' }}>
                              <p>Posted {moment1('2019-09-26 00:00:00', 'YYYY-MM-DD HH:mm:ss').tz('America/New_York').fromNow(true)} ago</p>
                                </span> */}
                              {/* <p dangerouslySetInnerHTML={{ __html: post.desc.substring(0,200) + '...' }} /> */}
                              <div className="mt-auto">
                                <Link to='/TableauVsQlik'>
                                  <button className="btn btn-sm button_mobile" onClick={handleLinkClick}>
                                    Read More
                                  </button>
                                </Link>
                              </div>
                            </div>
                          </div>
                          </div>
                          </div>

              </div>
            </div>
            {/* <div className="container d-flex justify-content-between">
              <button
                type="button"
                className="btn btn-dark"
                style={{
                  backgroundColor: '#1B2951',
                  color: 'white',
                }}
                onClick={handlePrevClick}
                disabled={currentPage === 1}
              >
                Previous
              </button>
              <button
                type="button"
                className="btn btn-success"
                style={{
                  backgroundColor: '#89C000',
                  color: 'white',
                  border: 'none',
                }}
                onClick={handleNextClick}
                disabled={currentPage * pageSize >= posts.length}
              >
                Next
              </button>
            </div> */}
          </div>
        </div>
      </MediaQuery>
    </>
  );
}
