import React, { useEffect, useState } from 'react';
import Edit from '../Assets/Img/edit.png';
import Delete from '../Assets/Img/delete.png';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Menu from '../components/Menu';
import axios from 'axios';
// import moment from 'moment';
import moment1 from 'moment-timezone';

import { useContext } from 'react';
import { AuthContext } from '../context/authContext';
import userIcon from '../Assets/Img/favicon.png';
import MediaQuery from 'react-responsive';
import {Helmet} from "react-helmet";
import fav from '../Assets/Img/eigennet-500x500.ico'


export default function Single() {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])


  const [post, setPost] = useState({});
  const [cat_count, setCatCount] = useState({});
  
  const location = useLocation();
  const navigate = useNavigate();
  
  const postId = location.pathname.split('/')[2];
  
  const { currentUser } = useContext(AuthContext);
  const [mainPostId,setMainPostId] = useState(post.id)
  
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get(`/api/posts/${postId}`);
        setPost(res.data);
        const res_cat = await axios.get(`/api/posts/category/${postId}`);
        console.log('Count is :'+res_cat.data);
        setCatCount(res_cat.data);
        setMainPostId(res.data.id)
        // document.title = res.data.title;

        // console.log(res.data,postId,'-------')
      } catch (err) {
        console.log(err);
      }
    };
    fetchData();
  }, [postId]);
  console.log("Cat :"+cat_count.count);
  const handleDelete = async () => {
    try {
      await axios.delete(`/api/posts/${postId}`);
      navigate('/');
    } catch (err) {
      console.log(err);
    }
  };
  
  // const getText = (html) => {
    //   const doc = new DOMParser().parseFromString(html, 'text/html');
  //   return doc.body.innerHTML;
  // };

  console.log('id',mainPostId,post)
  

  return (
    <>
     <Helmet>
                <meta name='description' charSet="utf-8" />
                <title>{post?.title}</title>
                <link rel="icon" href={fav} />

            </Helmet>
      <MediaQuery minWidth={1160}>
        <div className="container">
          <div className="single mt-3">
            <div className="content">
              <img id='imgHead' src={post?.img} alt="Img" />
              <div className="user">
                <img src={userIcon} style={{ marginTop: '-21px' }} alt="Eigennet LLC" />
                <div className="info">
                  <span>Name: {post.username}</span><br/>
                  <span>Posted {moment1(post.date, 'YYYY-MM-DD HH:mm:ss').tz('America/New_York').fromNow(true)}</span>
                </div>
                {currentUser && currentUser.username === post.username && (
                  <div className="edit">
                    <Link to={`/write?edit=2`} state={post}>
                      <img src={Edit} alt="edit" />
                    </Link>
                    <img onClick={handleDelete} src={Delete} alt="delete" />
                  </div>
                )}
              </div>
              <h1 style={{fontSize:'2rem'}}>{post.title}</h1>
              <div dangerouslySetInnerHTML={{ __html: post.desc }} />
              <span className='font-weight-bold' style={{color:'#3F729B'}}>{post.hashtag ? post.hashtag : ""}</span>
              
            </div>
            {cat_count.count > 1 ? (
              <Menu cat={post.cat} mainPostId={mainPostId} />
              ) : (
              <h3>No similar posts.</h3>
              )}
          </div>
        </div>
      </MediaQuery>

      <MediaQuery maxWidth={1160}>
        <div className="container">
          <div className="single mt-3">
            <div className="content">
              <img src={post?.img} alt="" />
              <div className="user">
                <img src={userIcon} style={{ marginTop: '-21px' }} alt="Img" />
                <div className="info">
                  <span>Name: {post.username}</span><br/>
                  <span>Posted {moment1(post.date, 'YYYY-MM-DD HH:mm:ss').tz('America/New_York').fromNow(true)}</span>
                </div>
                {currentUser && currentUser.username === post.username && (
                  <div className="edit">
                    <Link to={`/write?edit=2`} state={post}>
                      <img src={Edit} alt="edit" />
                    </Link>
                    <img onClick={handleDelete} src={Delete} alt="delete" />
                  </div>
                )}
              </div>
              <h3>{post.title}</h3>
              <div dangerouslySetInnerHTML={{ __html: post.desc }} />
              <span className='font-weight-bold' style={{color:'#3F729B'}}>{post.hashtag ? post.hashtag : ""}</span>
            
            </div>
            </div>
            {cat_count.count > 1 ? (
              <Menu cat={post.cat} mainPostId={mainPostId} />
              ) : (
              <h3>No similar posts.</h3>
              )}         
        </div>
      </MediaQuery>
    </>
  );
}
