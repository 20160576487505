import React from 'react';
import dpvsdm_pdf from '../Assets/Img/dpvsdm/Document_Processing_VS_Document_Management.pdf';
import { Viewer, Worker } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';

import MediaQuery from 'react-responsive';


export default function DocumentProcessingVsDocumentManagement() {
    const defaultLayoutPluginInstance = defaultLayoutPlugin();
    return (
        <>
        {/* Laptop mode */}
        <MediaQuery minWidth={1160}>
        <div className='container-fluid' style={{ height: '100vh' }}>
            <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.0.279/build/pdf.worker.min.js">
                <Viewer
                    fileUrl={dpvsdm_pdf}
                    plugins={[defaultLayoutPluginInstance]}
                    defaultScale={1} // Set the default scale to 1 (100%)
                    // theme="dark" // Set the theme to "dark"
                />
            </Worker>
            {/* <style>
                {`
                    .rpv-core__icon {
                        display: none !important;
                    }
                `}  
            </style> */}
        </div>
        </MediaQuery>



        {/* Tablet or ipad mode  */}
        <MediaQuery minWidth={768} maxWidth={1159}>
        <div className='container-fluid' style={{ height: '100vh' }}>
        <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.0.279/build/pdf.worker.min.js">
                        <Viewer
                            fileUrl={dpvsdm_pdf}
                            plugins={[defaultLayoutPluginInstance]}
                            defaultScale={0.9} // Set the default scale to 1 (100%)
                            // theme="dark" // Set the theme to "dark"
                        />
                    </Worker>
                    {/* <style>
                        {`
                            .rpv-core__icon {
                                display: none !important;
                            }
                        `}  
                    </style> */} 
                    </div>
        </MediaQuery>

        {/* Mobile mode */}
        <MediaQuery maxWidth={767}>
        <div className='container-fluid' style={{ height: '100vh' }}>
            <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.0.279/build/pdf.worker.min.js">
                <Viewer
                    fileUrl={dpvsdm_pdf}
                    plugins={[defaultLayoutPluginInstance]}
                    defaultScale={0.6} // Set the default scale to 1 (100%)
                    // theme="dark" // Set the theme to "dark"
                />
            </Worker>
            {/* <style>
                {`
                    .rpv-core__icon {
                        display: none !important;
                    }
                `}  
            </style> */}
        </div>
        </MediaQuery>

        </>
    );
}
