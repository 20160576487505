import React from 'react'
import {Helmet} from "react-helmet";

export default function HelmetForTitle() {
  return (
    <div>
          <Helmet>
                <meta name='description' charSet="utf-8" />
                <title>Eigennet - Engineering The Mission With Innovation!</title>

            </Helmet>
    </div>
  )
}
