import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import navlogo from '../Assets/Img/eigennet_RESEARCHLAB_logo.svg';
import { AuthContext } from '../context/authContext';
import LoadingBar from 'react-top-loading-bar';

export default function NavBar(props) {
  const { currentUser, logout } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(false);
  const [loadingProgress, setLoadingProgress] = useState(0);

  const handleLinkClick = () => {
    setIsLoading(true);
    setLoadingProgress(30); // Set initial progress value

    // Simulate loading delay (remove this in your actual code)
    setTimeout(() => {
      setLoadingProgress(100); // Set progress to complete
      setIsLoading(false);

      // Reset progress after a delay (optional)
      // setTimeout(() => {
      //   setLoadingProgress(0);
      // }, 500);
    }, 1000);
  };

  return (
    <>
      <LoadingBar progress={loadingProgress} height={5} color="#89C000" />
    <div>

      <nav className="navbar navbar-expand-xl navbar-light shadow p-0" style={{ backgroundColor: '#eaeaea' }}>
        <Link className="site-logo" onClick={handleLinkClick} to="/" data-toggle="collapse" data-target=".navbar-collapse.show">
          <img src={navlogo} style={{ width: '200px' }} alt="Eigennet"></img>
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarNavDropdown"
          aria-controls="navbarNavDropdown"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarNavDropdown">
          <ul className="navbar-nav ml-auto">
            <li className="nav-item ml-2">
              <a
                className="nav-link"
                target="_blank"
                style={{ fontWeight: '500', color: '#1B2951' }}
                rel="noopener nofollow noreferrer"
                href="https://www.eigennet.com/"
              >
                Home
              </a>
            </li>

            {/* <li className="nav-item dropdown ml-2">
              <Link
                className="nav-link dropdown-toggle"
                id="navbarDropdownMenuLink"
                data-toggle="dropdown"
                style={{ fontWeight: '500', color: '#1B2951' }}
                aria-haspopup="true"
                aria-expanded="false"
              >
                Categories
              </Link>
              <ul className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                <li>
                  <Link
                    className="dropdown-item"
                    to="/?cat=AI and IT Industry Trends"
                    style={{ color: '#1B2951' }}
                    data-toggle="collapse"
                    data-target=".navbar-collapse.show"
                    onClick={handleLinkClick}
                  >
                    AI and IT Industry Trends
                  </Link>
                </li>
                <li>
                  <Link
                    className="dropdown-item"
                    to="/?cat=Use Cases and Case Studies"
                    style={{ color: '#1B2951' }}
                    data-toggle="collapse"
                    data-target=".navbar-collapse.show"
                    onClick={handleLinkClick}
                  >
                    Use Cases and Case Studies
                  </Link>
                </li>
                <li>
                  <Link
                    className="dropdown-item"
                    to="/?cat=Company News"
                    style={{ color: '#1B2951' }}
                    data-toggle="collapse"
                    data-target=".navbar-collapse.show"
                    onClick={handleLinkClick}
                  >
                    Company News
                  </Link>
                </li>
                <li>
                  <Link
                    className="dropdown-item"
                    to="/?cat=Opinion Pieces"
                    style={{ color: '#1B2951' }}
                    data-toggle="collapse"
                    data-target=".navbar-collapse.show"
                    onClick={handleLinkClick}
                  >
                    Opinion Pieces
                  </Link>
                </li>
                <li>
                  <Link
                    className="dropdown-item"
                    to="/?cat=Guest Blogs"
                    style={{ color: '#1B2951' }}
                    data-toggle="collapse"
                    data-target=".navbar-collapse.show"
                    onClick={handleLinkClick}
                  >
                    Guest Blogs
                  </Link>
                </li>
              </ul>
            </li> */}

            <li className="nav-item ml-2">
              <Link
                className="nav-link"
                style={{ fontWeight: '500', color: '#1B2951' }}
                to="/contactUs"
                data-toggle="collapse"
                data-target=".navbar-collapse.show"
                onClick={handleLinkClick}
              >
                Contact Us
              </Link>
            </li>
            {currentUser && (
              <li className="nav-item ml-2">
                <Link
                  className="nav-link"
                  style={{ fontWeight: '500', color: '#1B2951' }}
                  to="/write"
                  data-toggle="collapse"
                  data-target=".navbar-collapse.show"
                  onClick={handleLinkClick}
                >
                  Write
                </Link>
              </li>
            )}
            <li className="nav-item ml-2">
            {currentUser?.username ? <Link
                className="nav-link"
                style={{ fontWeight: 'bold', color: '#1B2951' }}
                data-toggle="collapse"
                data-target=".navbar-collapse.show"
              >
               {currentUser?.username}
              </Link>: ''}
            </li>

            <li className="nav-item ml-2 mr-2">
              {currentUser ? <Link
                className="nav-link"
                style={{ fontWeight: '500', color: '#1B2951' }}
                data-toggle="collapse"
                data-target=".navbar-collapse.show"
              >
                <span onClick={logout}>Logout</span>
              </Link>: ''}
            </li>
          </ul>
        </div>
      </nav>
    </div>
    </>
  );
}
