import {
  createBrowserRouter,
  RouterProvider,
  // Route,
  Outlet,
} from "react-router-dom";
import Register from "./pages/Register";
import Login from "./pages/Login";
import Write from "./pages/Write";
import Home from "./pages/Home";
import Single from "./pages/Single";
import Navbar from "./components/NavBar";
import Footer from "./components/Footer";
import "./style.scss"
import Error404 from "./pages/Error404";
import { ContactUs } from "./pages/ContactUs";
import ScrollToTop from "react-scroll-to-top";
import BtcBlog from "./OldBlogs/BtcBlog";
import FemaTableau from "./OldBlogs/FemaTableau";
import Tableau from "./OldBlogs/Tableau";
import TableuVsQlik from "./OldBlogs/TableuVsQlik";
import DocumentProcessingVsDocumentManagement from "./pages/DocumentProcessingVsDocumentManagement";


const Layout = () => {
  return (
    <>
      <Navbar />
      <Outlet />
      <Footer />
    </>
  );
};

const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    children: [
      {
        path: "/",
        element: <Home />,
      },
      {
        path: "/post/:id",
        element: <Single />,
      },
      {
        path: "/write",
        element: <Write />,
      },
      {
        path: "/contactUs",
        element: <ContactUs />,
      },
      {
        path: "/BtcBlog",
        element: <BtcBlog />,
      },
      {
        path: "/FemaTableau",
        element: <FemaTableau />,
      },
      {
        path: "/Tableau",
        element: <Tableau />,
      },
      {
        path: "/TableauVsQlik",
        element: <TableuVsQlik />,
      },
      {
        path: "/DocumentProcessingVsDocumentManagement",
        element: <DocumentProcessingVsDocumentManagement />,
      },
      {
        path: "*",
        element: <Error404 />,
      },
    ],
  },
  {
    path: "/register",
    element: <Register />,
  },
  {
    path: "/login",
    element: <Login />,
  },
]);

function App() {
  return (   
    <>

  <div style={{
    display: 'flex',
    minHeight: '100vh',
    flexDirection: 'column',
  }}>
    <div style={{ flex: 1 }}>
    <div className="app">
      <div className="container-fluid pl-0 pr-0">
        <RouterProvider router={router} />
      </div>
    
      
    </div>
    </div>
    </div>
    <div >
      <ScrollToTop smooth />
    </div>
    </>
  );
}

export default App;
