import React, { useEffect } from 'react';
import tabvsqlik from '../Assets/Img/tableuvsqlik1.jpg'


export default function TableuVsQlik() {
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
  return (
    <>

    <div className='container' >
        <img src={tabvsqlik} alt="TableauVsQlik" style={{width:'-webkit-fill-available'}}/>
    </div>
   
    </>
  )
}
