import React, { useContext, useState } from 'react'
import '../style.scss'
import {
  Link, useNavigate,
} from "react-router-dom";
import axios from 'axios';
import { AuthContext } from '../context/authContext';
import navlogo from "../Assets/Img/eigennet_RESEARCHLAB_logo.svg"
import HelmetForTitle from '../components/HelmetForTitle';


export default function Login() {

  const {currentUser} = useContext(AuthContext)

  const [inputs, setInputs]=useState({
    username:'',
    email:'',
    password:''
  })

  const [err,setErr]= useState(null)

  const handleChange = e =>{
setInputs(prev=>({...prev,[e.target.name]:e.target.value}))
  }
const navigate = useNavigate()
  // console.log(inputs)
const handleSubmit = async e =>{
  e.preventDefault()   
  try{
  // const res = await axios.post("/auth/register",inputs)
  await axios.post('/auth/register',inputs)
  navigate('/login')
}catch(err){
  setErr(err.response.data)
}
}

  return (
    <>
    {/* <Helmet/> */}
    <HelmetForTitle/>
    <div className='auth'>
      <div className='container'>
        <div className="row">
          <div className="col-sm-12 col-md-6 col-lg-6" style={{display:'flex',alignItems:'center',justifyContent:'center'}}>
      <img src={navlogo} style={{width:'350px'}}  alt="Eigennet"></img><br></br>
    </div>
    <div className="col-sm-12 col-md-6 col-lg-6" >

    <form>
    <h1 className='text-center'>Register</h1>
    <input type="text" placeholder= 'username' name='username'  onChange={handleChange}/>
    <input type="email" placeholder= 'email' name='email' onChange={handleChange}/>
    <input type="password" placeholder='password' name='password' onChange={handleChange}/>
    {currentUser && <button onClick={handleSubmit}>Register</button>}
    {err && <p>{err}</p>}
    <span>Already have an account ?<br/><Link to='/Login'>Login</Link></span>
    </form>   
    </div>
     </div>
     </div>
     </div>
     </>
  )
}
