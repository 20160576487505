import React, { useEffect, useState } from 'react'
import '../style.css'
import axios from 'axios'
import { Link,useLocation } from 'react-router-dom'
import MediaQuery from 'react-responsive';
import BGM from '../Assets/Img/BgmForBlog.png'
import BGM1 from '../Assets/Img/BGM.png'
import { Tooltip } from 'react-tooltip'
import dsvdm1 from '../Assets/Img/dpvsdm/Cover-upload-blog-PVSM.png'
// import moment from 'moment';



// const getText = (html) =>{
//   const doc = new DOMParser().parseFromString(html, "text/html")
//   return doc.body.textContent
// }

export const HeroSect = () => {
  
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const [posts1,setPosts1] = useState([]);

  const cat = useLocation().search
  
  useEffect(()=>{
    const fetchData2 = async () =>{
      try{
        const res = await axios.get(`/api/posts${cat}`)
        setPosts1(res.data);
        
      } catch (err){
        console.log(err)
      }
    };
    fetchData2();
  },[cat])
  return (
    
    <>
    {/* Laptop view */}
          <MediaQuery maxWidth={1800}>
       <MediaQuery minWidth={1160}>
  <section id="hero" className="d-flex flex-column">

<div className="container-fluid bg-image"
style={{width:'100%', backgroundImage: `url(${BGM})`,backgroundSize: 'cover',backgroundRepeat:'no-repeat',height:'230px'}}>
  <div className="container carousel-container justify-content-center">
    <h1 className="animate__animated animate__fadeInDown" style={{fontSize:'3.3rem',lineHeight:'2.5rem',marginBottom:'30px',color:'#1B2951'}}><b>Eigennet Research Lab</b></h1>
    <h5 className="animate__animated animate__fadeInDown" style={{fontSize:'22px',lineHeight:'1.5rem',color:'#1B2951',fontWeight:'500'}}>Find our latest blog posts here. Stay in touch with industry insights, expert opinions, and best practices</h5>
  </div>
</div>



{/*     
    <svg className="hero-waves" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 24 150 28" preserveAspectRatio="none">
      <defs>
        <path id="wave-path" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
      </defs>
      <g className="wave1">
        <use xlinkHref="#wave-path" x="50" y="3" fill="rgba(255,255,255,0.1)" />
      </g>
      <g className="wave2">
        <use xlinkHref="#wave-path" x="50" y="0" fill="rgba(255,255,255,0.1)" />
      </g>
      <g className="wave3">
        <use xlinkHref="#wave-path" x="50" y="9" fill="#fff" />
      </g>
    </svg> */}
{/* 
    <div className="container-fluid bg-image"
style={{width:'100%', backgroundImage: `url(${BGM})`,backgroundSize: 'cover',backgroundRepeat:'no-repeat',height:'300px'}}>
    
   
        </div> */}
    </section>
    </MediaQuery>
    </MediaQuery>

{/* Ultra wide screen view */}
       <MediaQuery minWidth={1801}>
  <section id="hero" className="d-flex flex-column">
  <div className="container-fluid bg-image"
style={{width:'100%', backgroundImage: `url(${BGM})`,backgroundSize: 'cover',backgroundRepeat:'no-repeat',height:'300px'}}>
  <div className="container carousel-container justify-content-center" style={{height:'14vh'}}>
    <h1 className="animate__animated animate__fadeInDown" style={{fontSize:'3.3rem',lineHeight:'2.5rem',marginBottom:'30px',color:'#1B2951'}}><b>Eigennet Research Lab</b></h1>
    <h5 className="animate__animated animate__fadeInDown" style={{fontSize:'22px',lineHeight:'1.5rem',color:'#1B2951',fontWeight:'500'}}>Find our latest blog posts here. Stay in touch with industry insights, expert opinions, and best practices</h5>
  </div>
</div>

    
   
    </section>
    </MediaQuery>


{/* mobile view */}
       <MediaQuery maxWidth={1159}>
  <section id="hero" className="d-flex flex-column">
  <div className="container-fluid bg-image"
style={{width:'100%', backgroundImage: `url(${BGM1})`,backgroundSize: 'cover',backgroundRepeat:'no-repeat',height:'230px'}}>
  <div className="container carousel-container justify-content-center" style={{height:'24vh',paddingLeft:'0'}}>
    <h1 className="animate__animated animate__fadeInDown" style={{fontSize:'3rem',lineHeight:'2.5rem',marginBottom:'30px',color:'#1B2951'}}><b>Eigennet Research Lab</b></h1>
    <h5 className="animate__animated animate__fadeInDown" style={{fontSize:'20px',lineHeight:'1.5rem',color:'#1B2951',fontWeight:'500'}}>Find our latest blog posts here. Stay in touch with industry insights, expert opinions, and best practices</h5>
  </div>
</div>

    

    </section>
    </MediaQuery>

    {/* <main id="main">

    <section id="Latest" className="Latest">
      <div className="container-fluid">

        <div className="section-title" data-aos="zoom-out">
          <h2>Our</h2>
          <p>Recent Blog</p>
        </div>
        <a href="#0" className="banner" aria-label="Shop now">
          {posts1.length > 0 &&(
          <div className="banner__grid">
            
            <div aria-hidden="true">
              <figure className="banner__figure" style={{backgroundImage: `url(./upload/${posts1[0].img})`}}></figure>
            </div>
        
            <div className="banner__text">
              <h3 className='font-weight-bold' style={{fontSize:'20px'}}>{posts1[0].title}</h3> */}
              {/* <span className="text-muted mb-3 " style={{ fontSize: '11px' }}>
                              Posted {moment(posts[0].date).fromNow()}                              </span> */}
              {/* <p style={{lineHeight:'1.2'}} dangerouslySetInnerHTML={{ __html: posts[0].desc.substring(0,200) + '...' }} />   */}
        
              {/* <p className="banner__link-wrapper">
                <span className="banner__link">
            <Link className="link" to={`/post/${posts1[0].id}`}>

                  <i>Read more</i>
                  </Link>
                </span>
              </p>
            </div>
          </div>
          )}
        </a> */}
        


      {/* </div>
    </section>
    </main> */}


{/* Laptop view */}
<MediaQuery maxWidth={1920}>
       <MediaQuery minWidth={1160}>  
         <main id="main">

<section id="Latest" className="Latest">
  <div className="container-fluid">

    <div className="section-title" data-aos="zoom-out">
      <h2>Our</h2>
      <p>Recent Blog</p>
    </div>
    <Tooltip id="my-tooltip" />
      {/* {posts1.length > 0 &&( */}
    <Link className="link banner" to="/DocumentProcessingVsDocumentManagement" target='_blank' rel="noreferrer" aria-label="Shop now" data-tooltip-id="my-tooltip" data-tooltip-content="Click to read the full blog">
      <div className="banner__grid">
        
        <div className="container-fluid bg-image"
style={{width:'100%', backgroundImage: `url(${dsvdm1})`,backgroundSize: 'cover',backgroundRepeat:'no-repeat',height:'300px'}}>
    
        <div className="banner__text">
          {/* <h3 className='font-weight-bold break-after-30' style={{fontSize:'20px'}}>{posts1[0].title}</h3> */}
          {/* <span className="text-muted mb-3 " style={{ fontSize: '11px' }}>
                          Posted {moment(posts[0].date).fromNow()}                              </span> */}
          {/* <p style={{lineHeight:'1.2'}} dangerouslySetInnerHTML={{ __html: posts[0].desc.substring(0,200) + '...' }} />   */}
    
          {/* <p className="banner__link-wrapper">
            <span className="banner__link">
        <Link className="link" to={`/post/${posts1[0].id}`}>

              <i>Read more</i>
              </Link>
            </span>
          </p> */}
        </div>
        </div>
      </div>
    </Link>
      {/* // )} */}
    
  </div>
</section>
</main>
</MediaQuery>
</MediaQuery>


{/* Ultra wide screen view */}
<MediaQuery minWidth={1921}>
    <main id="main">

<section id="Latest" className="Latest">
  <div className="container">

    <div className="section-titleUltrawide" data-aos="zoom-out">
      <h2>Our</h2>
      <p>Recent Blog</p>
    </div>
    <Tooltip id="my-tooltip" />

      {/* {posts1.length > 0 &&( */}
    <Link className="link bannerUltrawide" to="/DocumentProcessingVsDocumentManagement" target='_blank' rel='noreferrer' aria-label="Shop now" data-tooltip-id="my-tooltip" data-tooltip-content="Click to read the full blog">
      <div className="banner__grid">
        
        <div className="container bg-image"
style={{width:'100%', backgroundImage: `url(${dsvdm1})`,backgroundSize: 'cover',backgroundRepeat:'no-repeat',height:'300px'}}>
    
        <div className="banner__text">
          {/* <h3 className='font-weight-bold break-after-30' style={{fontSize:'20px'}}>{posts1[0].title}</h3> */}
          {/* <span className="text-muted mb-3 " style={{ fontSize: '11px' }}>
                          Posted {moment(posts[0].date).fromNow()}                              </span> */}
          {/* <p style={{lineHeight:'1.2'}} dangerouslySetInnerHTML={{ __html: posts[0].desc.substring(0,200) + '...' }} />   */}
    
          {/* <p className="banner__link-wrapper">
            <span className="banner__link">
        <Link className="link" to={`/post/${posts1[0].id}`}>

              <i>Read more</i>
              </Link>
            </span>
          </p> */}
        </div>
        </div>
      </div>
    </Link>
      {/* // )} */}
    
  </div>
</section>
</main>
</MediaQuery>


{/* tab or ipad view */}
<MediaQuery  minWidth={768} maxWidth={1159}>
    <main id="main">

<section id="Latest" className="Latest">
  <div className="container-fluid">

    <div className="section-titleMobile" data-aos="zoom-out">
      <h2>Our</h2>
      <p>Recent Blog</p>
    </div>
    <Link to='/DocumentProcessingVsDocumentManagement' target="_blank" rel='noreferrer' className="bannerTab" aria-label="Shop now">
      {/* {posts1.length > 0 &&( */}
      <div className="banner__grid">
        
        <div className="container-fluid bg-image"
style={{width:'100%', backgroundImage: `url(${dsvdm1})`,backgroundSize: 'contain',backgroundRepeat:'no-repeat',height:'300px'}}>
    
        <div className="banner__textMobile">
          {/* <h3 className='font-weight-bold break-after-30' style={{fontSize:'20px'}}>{posts1[0].title}</h3> */}
          {/* <span className="text-muted mb-3 " style={{ fontSize: '11px' }}>
                          Posted {moment(posts[0].date).fromNow()}                              </span> */}
          {/* <p style={{lineHeight:'1.2'}} dangerouslySetInnerHTML={{ __html: posts[0].desc.substring(0,200) + '...' }} />   */}
    
          <p className="banner__link-wrapper" style={{marginTop:'0rem'}}>
            <span className="banner__link">
        {/* <a className="link" target='_blank' rel='noreferrer' href={dsvdm_pdf}>

              <i>Read more</i>
              </a> */}
            </span>
          </p>
        </div>
        </div>
      </div>
      {/* )} */}
    </Link>
    
  </div>
</section>
</main>
</MediaQuery>

{/* Mobile view */}
<MediaQuery maxWidth={767}>
    <main id="main">

<section id="Latest" className="Latest">
  <div className="container-fluid">

    <div className="section-titleMobile" data-aos="zoom-out">
      <h2>Our</h2>
      <p>Recent Blog</p>
    </div>
    <Link to='/DocumentProcessingVsDocumentManagement' target="_blank" rel='noreferrer' className="bannerMobile" aria-label="Shop now">
      {/* {posts1.length > 0 &&( */}
      <div className="banner__grid">
        
        <div className="container-fluid bg-image"
style={{width:'100%', backgroundImage: `url(${dsvdm1})`,backgroundSize: 'contain',backgroundRepeat:'no-repeat',height:'300px'}}>
    
        <div className="banner__textMobile">
          {/* <h3 className='font-weight-bold break-after-30' style={{fontSize:'20px'}}>{posts1[0].title}</h3> */}
          {/* <span className="text-muted mb-3 " style={{ fontSize: '11px' }}>
                          Posted {moment(posts[0].date).fromNow()}                              </span> */}
          {/* <p style={{lineHeight:'1.2'}} dangerouslySetInnerHTML={{ __html: posts[0].desc.substring(0,200) + '...' }} />   */}
    
          <p className="banner__link-wrapper" style={{marginTop:'0rem'}}>
            <span className="banner__link">
        {/* <a className="link" target='_blank' rel='noreferrer' href={dsvdm_pdf}>

              <i>Read more</i>
              </a> */}
            </span>
          </p>
        </div>
        </div>
      </div>
      {/* )} */}
    </Link>
    
  </div>
</section>
</main>
</MediaQuery>

</>
  )
}
