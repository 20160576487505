import React, { useState } from 'react'

import '../style.css'
// import moment from 'moment';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import HelmetForTitle from '../components/HelmetForTitle';

export const ContactUs = () => {
    const [inputs, setInputs]=useState({
        name:'',
        email:'',
        subject:'',
        message:'',
        date: moment(Date.now()).format("YYYY-MM-DD HH:mm:ss"),

      })
    
      const [err,setErr]= useState(null)
    
      const handleChange = e =>{
    setInputs(prev=>({...prev,[e.target.name]:e.target.value}))
      }
    const navigate = useNavigate()
      // console.log(inputs)
    const handleSubmit = async e =>{
      e.preventDefault()   
      try{
      // const res = await axios.post("/auth/register",inputs)
       const res = await axios.post(`/api/users/contactUs`,inputs)
       console.log(res)
      navigate('/')
    }catch(err){
      setErr(err.response.data)
    }
    }



  return (
    <>
    <HelmetForTitle/>
    <section id="contact" className="contact">
    <div className="container">

      <div className="section-title" data-aos="zoom-out">
        <h2>Contact</h2>
        <p>Contact Us</p>
      </div>

      <div className="row mt-5">

        <div className="col-lg-4" data-aos="fade-right">
          <div className="info">
            <div className="address">
              <i className="bi bi-geo-alt"></i>
              <h4>Location:</h4>
              <p>Headquarters: 7361 Calhoun Place, Suite 565 Rockville, MD 20855</p>
            </div>

            <div className="email">
              <i className="bi bi-envelope"></i>
              <h4>Email:</h4>
              <p>info@eigennet.com</p>
            </div>

            {/* <div className="phone">
              <i className="bi bi-phone"></i>
              <h4>Call:</h4>
              <p>(704)-909-6542</p>
            </div> */}

          </div>

        </div>

        <div className="col-lg-8 mt-5 mt-lg-0" data-aos="fade-left">

        <form>
      <div className="row">
        <div className="col-md-6 form-group">
          <input
            type="text"
            name="name"
            className="form-control"
            id="name"
            placeholder="Your Name"
            onChange={handleChange}            required
          />
        </div>
        <div className="col-md-6 form-group mt-3 mt-md-0">
          <input
            type="email"
            className="form-control"
            name="email"
            id="email"
            placeholder="Your Email"
            onChange={handleChange}            required
          />
        </div>
      </div>
      <div className="form-group mt-3">
        <input
          type="text"
          className="form-control"
          name="subject"
          id="subject"
          placeholder="Subject"
          onChange={handleChange}          required
        />
      </div>
      <div className="form-group mt-3">
        <textarea
          className="form-control"
          name="message"
          rows="5"
          placeholder="Message"
          onChange={handleChange}          required
        ></textarea>
      </div>
      <div className="text-center">
        <button className='btn btn-success' style={{backgroundColor:'#89c000',border:'none'}} onClick={handleSubmit}>
          Send Message
        </button>
    {err && <p>{err}</p>}

      </div>
    </form>


        </div>

      </div>

    </div>
  </section>
  </>
  )
}
